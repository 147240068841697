import React from 'react';
import {usePageContext} from './Page';
import Typography from '@material-ui/core/Typography';

export default function HeaderContent() {
  const {pageState: { headerContent}} = usePageContext();

  return (
    <div className="ml-2 mt-0"
      variant="subtitle"
      style={{flex:1, fontSize: '12px'}}
    >
      {headerContent}
    </div>
  );
}
