import { selector, selectorFamily, atom } from "recoil";
import {
  pusherAtom,
  pusherSelector,
  reducerKey,
} from "@bit/hlouzek.rhplus.pusher-recoil";
import { api } from "data/useData";
import _ from "lodash";

export const clientsAtom = atom({
  key: "clientsAtom",
  default: selector({
    key: "clientsSelector",
    get: async () => {
      const response = await api.clients();
      return response.data.map((item) => ({ id: item.id, label: item.name }));
    },
  }),
  dangerouslyAllowMutability: true
});

export const contractsAtom = atom({
  key: "contractsAtom",
  default: selector({
    key: "contractsSelector",
    get: async () => {
      const response = await api.contracts();
      return response.data.items.map((item) => ({
        id: item.id,
        label: item.name,
      }));
    },
  }),
  dangerouslyAllowMutability: true
});

const documentsSelector = selector({
  key: "documentsSelector",
  get: async () => {
    const response = await api.documents();
    return response.data.items;
  },
  dangerouslyAllowMutability: true,
});

export const documentsAtom = atom({
  key: "documentsAtom",
  default: documentsSelector,
  dangerouslyAllowMutability: true,
});

export const documentItemsSelector = selector({
  key: "documentItemsSelector",
  get: async ({ get }) => {
    const document = get(documentAtom);
    if (_.isEmpty(document)) return [];
    const response = await api.documentItems(document.id);
    return response.data.items;
  },
  dangerouslyAllowMutability: true,
});

export const documentItemsAtom = atom({
  key: "documentItemsAtom",
  default: documentItemsSelector,
  dangerouslyAllowMutability: true
});

// export const updateDocumentItemPusherSelector = pusherSelector({
//   key: 'update-document-item',
//   section: 'documentItems',
//   set: (state, {id, data}) => {
//     return state.map(item => {
//       if (item.id === id)
//         return {...item, ...data};
//       else
//         return item;
//     });
//   }
// })

// export const addDocumentPusherSelector = pusherSelector({
//   key: "add-document",
//   section: 'documents',
//   set: (state, data) => {
//     return [...state, data];
//   }
// // });

// export const updateDocumentPusherSelector = pusherSelector({
//   key: 'update-document',
//   section: 'documents',
//   set: (state, {id, data}) => {
//     return state.map(item => {
//       if (item.id === id)
//         return {...item, ...data};
//       else
//         return item;
//     });
//   }
// })

const documentSelector = selector({
  key: "documentSelector",
  get: async ({ get }) => {
    const documentNumber = get(documentNumberAtom);
    if (!documentNumber) return {};
    const response = await api.document(documentNumber);
    return response.data;
  },
  dangerouslyAllowMutability: true,
});

export const documentNumberAtom = atom({
  key: "documentNumberAtom",
  default: 0,
  dangerouslyAllowMutability: true
});

export const documentAtom = atom({
  key: "documentAtom",
  default: documentSelector,
  dangerouslyAllowMutability: true,
});

// export const addDocumentItemPusherSelector = {
//   value: 1,
//   key: "add-document-item",
//   section: (data) => {
//     const {documentItem: {documentId} = {}} = data;
//     return reducerKey('documentItems', documentId);
//   },
//   set: (state, data) => {
//     return [...state, data];
//   }
// };
