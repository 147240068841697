import LocalIdbBase from '@bit/hlouzek.rhplus.index-db/localIdbBase';
// import useApi from '@bit/hlouzek.rhplus.api';
import service from 'api/service';
import {api} from 'data/useData';

export default function LocalIdb(props) {
  const {lookups} = api;

  const {
    openDb,
    createTable, 
    getAll, 
    getById,
    addItem,
    addItems
  } = LocalIdbBase(window.indexedDB, 1);

  const open = () => {
    // openDb('rhplusDb', e => {
    //   createTable("clients", "id", true)
    //   .then(({fetchneeded}) => fetchneeded && lookups().then(e => addItems("clients", e.data.states)));
    // })
    // .then();
  }

  return {
    open,
    getAll,
    getById,
    addItem,
    addItems,
  }
}