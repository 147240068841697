import React, { useEffect, useReducer } from "react";
import { ConnectedRouter } from "connected-react-router";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import {
  PageContext,
  usePageContext,
  PageReducer,
} from "@bit/hlouzek.rhplus.page/page";
import configureStore, { history } from "./store";
import App from "./containers/App";
import localIdb from "localIdb";
import CacheBuster from "@bit/hlouzek.rhplus.cache-buster";
import "ag-grid-enterprise";
import { LicenseManager } from "ag-grid-enterprise";
import { DraggableModalProvider } from "ant-design-draggable-modal";
import { ConfigProvider } from "antd";
import "ant-design-draggable-modal/dist/index.css";
import "styles/app.scss";
import { PusherRecoilRoot } from '@bit/hlouzek.rhplus.pusher-recoil';

// configuraton AgGrid Enterprise
LicenseManager.setLicenseKey(
  "MTU1MzczMTIwMDAwMA==555b6946f1f4ee082a509e13fbc32c10"
);

// configuration Redux store
export const store = configureStore();
const { open } = localIdb();

const Apps = (props) => {
  const initialPageContext = usePageContext();
  const [pageState, pageDispatch] = useReducer(PageReducer, initialPageContext);

  useEffect(() => {
    open();
  }, []);

  return (
    <CacheBuster packageJson={import("../package.json")}>
      {({ loading, isLatestVersion, refreshCacheAndReload }) => {
        if (loading) return null;
        if (!loading && !isLatestVersion) {
          refreshCacheAndReload();
        }

        return (
            <PusherRecoilRoot
              clientKey={process.env.REACT_APP_PUSHER_CLIENT_KEY}
              cluster={process.env.REACT_APP_PUSHER_CLUSTER}
              mode={process.env.NODE_ENV}
              channelName={process.env.REACT_APP_PUSHER_CHANNEL}
            >
              <DraggableModalProvider>
                <PageContext.Provider value={{ pageState, pageDispatch }}>
                  <App {...props} />
                </PageContext.Provider>
              </DraggableModalProvider>
            </PusherRecoilRoot>
        );
      }}
    </CacheBuster>
  );
};

export default function MainApp() {
  return (
    <BrowserRouter history={history}>
      <ConfigProvider>
        <Provider store={store}>
          <Route path="/" component={Apps} />
        </Provider>
      </ConfigProvider>
    </BrowserRouter>
  );
}
