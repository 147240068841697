import {selector, atom, useResetRecoilState } from 'recoil';
import {pusherAtom, pusherSelector} from '@bit/hlouzek.rhplus.pusher-recoil';
import {FetchData} from 'data/useData';
import service from 'api/service';
import _ from 'lodash';

export const documentItemSelector = selector({
  key: 'worksorderSelector',
  get: async ({get}) => {
    const documentItemId = get(documentItemIdAtom);
    if (!documentItemId)
      return {};
    const response = await FetchData(service.documentItem(documentItemId));
    
    return response.data;
  },
  dangerouslyAllowMutability: true  
});

export const documentItemIdAtom = atom({
  key: 'documentItemIdAtom',
  default: 0
});

export const documentItemAtom = atom({
  key: 'documentItemAtom',
  default: documentItemSelector,
  dangerouslyAllowMutability: true
});
