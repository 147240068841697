
const LocalIdbBase = (indexedDB, version = 1) => {
  let db;

  const openDb = (databaseName, upgrade) => {
    let upgradeneeded = false;
    const req = indexedDB.open(databaseName, version);

    req.onupgradeneeded = event => {
      db = event.target.result;
      upgrade(event);
    };


    return new Promise((resolve, reject) => {
      req.onsuccess = event => {
        db = event.target.result;
        resolve({db, event})
      };
      req.onerror = event => reject(event);
    });
  }

  const createTable = (tableName, keyPath, autoIncrement) => {
    let createdTable;
    return new Promise((resolve, reject) => {
      try {
        createdTable = db.objectStoreNames.contains(tableName);
        if (!createdTable) db.createObjectStore(tableName, {keyPath, autoIncrement});
        resolve({db, fetchneeded: !createdTable});
      }
      catch(error) {
        reject(error);
      }
    })
  }

  const getStore = (tableName) => {
    return new Promise((resolve, reject) => {
      try {
        const transaction = db.transaction([tableName], "readwrite");
        const store = transaction.objectStore(tableName);
        resolve({store, transaction});  
      }
      catch (error) {
        reject(error);
      }
    })
  }

  const getAll = (tableName) => {
    return new Promise((resolve, reject) => {
      getStore(tableName)
        .then(({store}) => {
          const req = store.getAll();
          req.onerror = event => reject(event);
          req.onsuccess = event  => resolve({db, result: event.target.result});
        });
    })
  }

  const getById = (tableName, id) => {
    return new Promise((resolve, reject) => {
      getStore(tableName)
      .then(({store}) => {
        const req = store.GetById(id);
        req.onerror = event => reject(event);
        req.onsuccess = event => resolve({db, result: event.target.result});  
      })
    })
  }
  
  const addItem = (tableName, item) => {
    return new Promise((resolve, reject) => {
      getStore(tableName)
        .then(({store}) => {
          const req = store.add(item);
          req.onerror = event => reject(event);
          req.onsuccess = event => resolve({db, event});  
          })
    });
  }

  const addItems = (tableName, items) => {
    return new Promise((resolve, reject) => {
      getStore(tableName)
        .then(({store, transaction}) => {
          items.forEach(item => store.add(item));

          transaction.oncomplete = event => resolve({db, event})
          transaction.onerror = event => reject(event);
        })
    })
  }

  return {
    openDb,
    createTable,
    getAll,
    getById,
    addItem,
    addItems
  }
}

export default LocalIdbBase;