import React from 'react';
import { usePageContext } from './redux/PageContext';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';

export default function IconButtons() {
  const { pageState: { iconButtons } } = usePageContext();

  return (
    <>
      {
        iconButtons.map(iconButton => {
          const {key, className, type} = iconButton;
          const liClassName = `list-inline-item ${key}`;
          return (
            type === "custom" ? (
              <li className={liClassName} key={key}>
              <div className="quick-menu">
                <IconButton id={key} className="icon-btn">
                  <i className={className} />
                </IconButton>
              </div>
            </li>
            ) : (
              <li className={liClassName} key={key}>
              <div className="quick-menu">
                <IconButton id={key} className="icon-btn">
                  <Icon>{key}</Icon>
                </IconButton>
              </div>
            </li>
            ))
          
        })
      }
    </>
  )
}

