import * as React from "react";
import {
  documentsAtom,
  documentItemsAtom,
  documentNumberAtom,
  documentAtom,
  // addDocumentSelector,
  // addDocumentItemSelector,
  // updateDocumentSelector,
  contractsAtom,
  clientsAtom
} from "recoils/documents";
import {
  documentItemIdAtom,
  documentItemAtom,
  documentItemSelector
} from 'recoils/documentItem';
import { useRecoilState, useRecoilValue, useRecoilRefresher_UNSTABLE } from "recoil";
import service from "api/service";
import { useApiComponent } from "@bit/hlouzek.rhplus.api";
import moment from "moment";
import { usePusher } from "@bit/hlouzek.rhplus.pusher-recoil";

export const FetchData = async (api, data) => {
  const { callApi } = useApiComponent(null);
  try {
    const response = await callApi({ ...api, paramObject: data });
    return response;
  }
  catch (err) {
    console.log("err", {...err});
  } 
};

export const api = {
  documents: () => FetchData(service.documents, {}),
  document: documentNumber => FetchData(service.document(documentNumber)),
  documentItems: documentId => FetchData(service.documentItems(documentId)),
  createBox: title => FetchData(service.createBox, { title }),
  createPallet: () => FetchData(service.createPallet),
  searchProducts: async fulltext => await FetchData(service.searchProducts, {fulltext}),
  searchPallets: async fulltext => await FetchData(service.searchPallets, {fulltext}),
  uploadDocumentPhoto: data => FetchData(service.uploadDocumentPhoto, data),
  uploadPositionPhoto: data => FetchData(service.uploadPositionPhoto, data),
  contracts: () => FetchData(service.contracts),
  clients: () => FetchData(service.clients),
  deleteFiles: data => FetchData(service.deleteFiles, data),
  printBoxTickets: ids => FetchData(service.printBoxTickets, {ids}),
};

export default function useData() {
  // const updateDocument = UsePusherRecoilSetter(updateDocumentPusherSelector);
  // const addDocument = UsePusherRecoilSetter(addDocumentSelector);
  //const addDocumentItem = UsePusherRecoilSetter(addDocumentItemPusherSelector);
  const [documents, setDocuments] = useRecoilState(documentsAtom);  
  const [, setDocumentNumber] = useRecoilState(documentNumberAtom);
  const document = useRecoilValue(documentAtom);
  const [documentItems, setDocumentItems] = useRecoilState(documentItemsAtom);
  const [documentItemId, setDocumentItemId] = useRecoilState(documentItemIdAtom);
  const [documentItem, setDocumentItem] = useRecoilState(documentItemAtom);
  const resetDocumentItem = useRecoilRefresher_UNSTABLE(documentItemSelector);
  const contracts = useRecoilValue(contractsAtom);
  const clients = useRecoilValue(clientsAtom);
  const { trigger } = usePusher();

  // const { getStateByCode } = useStates();
  // const { state } = useStates();

  const [isLoading, setIsLoading] = React.useState(false);

  const createDocumentAsync = async data => {
    const {note, contract, clientId, contractId, registrationNumber, folder, contractEditable} = data;
    setIsLoading(true);
    const insert = new FormData();
    insert.append("Note", note);
    insert.append("Folder", folder);
    if (contract) {
      if (contractEditable)
        insert.append("Contract", contract);
      else
        insert.append("ContractId", contract);
    }
    if (clientId) insert.append("ClientId", clientId);
    if (registrationNumber) insert.append("RegistrationNumber", registrationNumber);
    try {
      var inserted = await FetchData(service.createDocument, insert);
      setIsLoading(false);
    } catch (error) {
      console.log("error", {...error});
      setIsLoading(false);
    }
    trigger("document-add", inserted.data).then();
    // addDocument(inserted.data);
  };

  const createDocumentItemAsync = async data => {
    setIsLoading(true);
    try {
      var inserted = await FetchData(service.createDocumentItem, data);
    } catch (error) {
      console.log("error", {...error});
      setIsLoading(false);
    }
    trigger("documentitem-add", inserted.data).then();
    // addDocumentItem(inserted.data);
  }

  return {
    documents,
    setDocuments,
    createDocumentAsync,
    createDocumentItemAsync,
    isLoading,
    setDocumentNumber,
    setDocumentItemId,
    resetDocumentItem,
    document,
    documentItems,
    setDocumentItems,
    documentItem,
    setDocumentItem,
    contracts,
    clients
  };
}
