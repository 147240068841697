
const documents = {
  url: `/api/document/documents`,
  methodType: "GET",
  version: "1.0",
  compression: false,
}

const document = (documentNumber) => {
  return {
    url: `/api/document/document/${documentNumber}`,
    methodType: "GET",
    version: "1.0",
    compression: true,
  };
};

const documentItems = (documentId) => {
  return {
    url: `/api/documentitem/documentitems/${documentId}`,
    methodType: "GET",
    version: "1.0",
    compression: true,
  };
};

const createDocument = {
  url: `/api/document/createdocument`,
  methodType: "PUT",
  version: "1.0",
  compression: true,
}

const createDocumentItem = {
  url: `/api/documentitem/createdocumentitem`,
  methodType: "POST",
  version: "1.0",
  compression: true,
}

const createPallet = {
  url: `/api/position/createstockinposition`,
  methodType: "PUT",
  version: "1.0",
  compression: true,
}

const lookups = {
  url: '/api/lookup/all',
  methodType: "GET",
  version: "1.0",
  compression: false
}

const searchProducts = {
    url: `/api/product/fulltextsearch`,
    methodType: "POST",
    version: "1.0",
    compression: true
}

const searchPallets = {
    url: `/api/position/fulltextsearch`,
    methodType: "POST",
    version: "1.0",
    compression: true
}

const uploadPositionPhoto = {
  url: '/api/filestorage/uploadpositionphoto',
  methodType: 'PUT',
  version: "1.0"
}

const uploadDocumentPhoto = {
  url: '/api/filestorage/uploaddocumentphoto',
  methodType: 'PUT',
  version: "1.0"
}

const printBoxTicket = {
  url: '/api/print/printboxticket',
  methodType: 'POST',
  version: "1.0"
}

const printBoxTickets = {
  url: '/api/print/printboxtickets',
  methodType: 'POST',
  version: "1.0"
}

const printRackTicket = {
  url: '/api/print/printrackticket',
  methodType: 'POST',
  version: "1.0"
}

const thumbnails = positionId => {
  return {
    url: `/api/position/thumbnails/${positionId}`,
    methodType: 'GET',
    version: "1.0"  
  }
}

const documentItem = id => {
  return {
    url: `/api/documentitem/documentitem/${id}`,
    methodType: 'GET',
    version: "1.0"  
  }
}

const contracts = {
    url: `/api/lookup/contracts`,
    methodType: 'GET',
    version: "1.0"  
}

const clients = {
  url: `/api/lookup/clients`,
  methodType: 'GET',
  version: "1.0"  
}

const deleteFiles = {
  url: '/api/documentitem/deletefiles',
  methodType: 'POST',
  version: "1.0"
}

const downloadBoxTicket = id => {
  return {
  url: `/api/print/downloadboxticket/${id}`,
  methodType: 'GET',
  version: "1.0"
}};

const downloadBoxTickets = id => {
  return {
  url: `/api/print/downloadboxtickets/${id}`,
  methodType: 'GET',
  version: "1.0"
}};


const downloadRackTicket = id => {
  return {
  url: `/api/report/downloadrackticket/${id}`,
  methodType: 'GET',
  version: "1.0"
}};

export default {
  documents,
  createDocument,
  createDocumentItem,
  createPallet,
  document,
  documentItem,
  documentItems,
  lookups,
  searchProducts,
  searchPallets,
  uploadPositionPhoto,
  thumbnails,
  printBoxTicket,
  printBoxTickets,
  printRackTicket,
  contracts,
  clients,
  uploadDocumentPhoto,
  deleteFiles,
  downloadBoxTicket,
  downloadRackTicket,
  downloadBoxTickets
}